<template>
    <v-container
      id="payment"
      fluid
      tag="section"
      style="height: calc(100% - 90px);"
      class="container-full-size"
    >
      <v-col
        cols="12"
      >
        <v-btn
          color="default"
          class="mr-0"
          @click="back()"
        >
          {{ str['back'] }}
        </v-btn>
      </v-col>
      <template>
        <client-payment
          :payment="payment"
          :page="true"
        />
      </template>
  </v-container>
</template>

<script>
  import Utils from '@/services/Utils'

  export default {
    components: {
      ClientPayment: () => import('@/views/dashboard/components/client/Payment'),
    },
    data () {
      const paymentCache = Utils.getStorage('payment')
      const payment = paymentCache ? paymentCache : {}
      return {
        str: window.strings,
        payment: payment,
      }
    },
    methods: {
      back: function () {
        this.$router.back()
      },
    },
  }
</script>
